<template>
  <section class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">Get A Runner</h1>
      <h3 class="container ls-page-header-subtitle">
        Get what you need ordered and delivered by our trusted runners to any
        recipient in Zimbabwe on your behalf
      </h3>
    </div>
    <div class="ps-section__content ls-page-content">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-2 col-12"></div>
        <div class="col-xl-8 col-lg-6 col-md-8 col-12">
          <router-view></router-view>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-2 col-12"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BaseRunnerRequests",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  }
};
</script>

<style scoped></style>
